import moment from 'moment';
import { useEffect } from 'react';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
// import { delRequest } from 'config/axios';
import Loading from 'components/body/Loading';
import Paginate from 'components/footers/paginate';
import { useDispatch, useSelector } from 'react-redux';
import { exportExcel, getListMethod, getListOfPrintedView } from 'redux/SuratReducers/ListSuratMasuk/ListSuratAction';
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from 'utils/pagination';
// import {
//   checkError,
//   setLoading,
//   setStatus,
// } from 'redux/GeneralReducers/GeneralActions';

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers, LoginReducers } = useSelector(
    (state) => state
  );
  const { loading, status, msg } = GeneralReducers;
  const { start_date, end_date } = ListSuratReducers;
  const { items, meta } = ListSuratReducers.listBankSuratMasuk;
  let satuan_kerja_id =
    LoginReducers.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || 0;
  const getList = (current_page = 1, per_page = 10) => {
    dispatch(
      getListMethod(
        `master/bank-mail-masuk-internal`,
        'listBankSuratMasuk',
        current_page,
        satuan_kerja_id,
        per_page
      )
    );
    dispatch(
      getListOfPrintedView(
        `bank-mail-masuk-internal`,
        'listBankSuratMasukPrinted',
      )
    );
  };

  const downloadExcel = () => {
    dispatch(
      exportExcel(
        `bank-mail-masuk-internal`,
        'listBankSuratMasukPrinted',
      )
    );
  }
  console.log("ListSuratReducers", ListSuratReducers)
  // const deleteSurat = (id) => {
  //   dispatch(setLoading(true));
  //   delRequest(`egs/master/bank-mail/${id}`)
  //     .then((res) => {
  //       dispatch(setLoading(false));
  //       dispatch(setStatus({ status: 'success-bank-surat', msg: 'Success !' }));
  //     })
  //     .catch((err) => dispatch(checkError(err, 'fail-bank-surat')));
  // };

  useEffect(getList, [start_date, end_date]);


  return (
    <Fragment>
      <div className='content-title'></div>
      <div className='content-body'>
        {status === 'success-bank-surat' && (
          <div className='alert alert-success'>{msg}</div>
        )}
        {status === 'fail-bank-surat' && (
          <div className='alert alert-danger'>{msg}</div>
        )}
        <div className="form-group row" style={{ justifyContent: "flex-end" }}>
          <div className="col-md-2">
            <div className="dropdown">
              <button style={{ backgroundColor: "transparent", borderColor: "#eaeaea" }} className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                Export
              </button>
              <div className="dropdown-menu">
                <Link rel="noopener noreferrer" className="dropdown-item" to="# " onClick={downloadExcel}><i className="fa fa-file-excel-o" ></i> Excel</Link>
                <Link className="dropdown-item" to={{ pathname: "/bank-surat/list-surat/listBankSuratMasukPrinted" }}><i className="fa fa-file-pdf-o"></i> PDF</Link>
              </div>
            </div>
          </div>
          <div className="col-md-3" style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "end" }}>
            <label htmlFor="status_surat">Tampilkan</label>
            <select
              className="form-control"
              onChange={(e) => {
                getList(1, e.target.value)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
            <label htmlFor="status_surat">Entri</label>
          </div>
        </div>
        <Loading isLoading={loading} />
        <table className='table table-striped table-list'>
          <thead>
            <tr>
              <td width='130px'>Tanggal Terima</td>
              <td>Nomor Surat</td>
              <td>Hal</td>
              <td>Pengirim</td>
              <td>Penerima</td>
              <td>Disposisi</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((list, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Link to={`/bank-surat/masuk/list/detail/${list.id}`}>
                        {moment(list.tanggal).format('LL')}
                      </Link>
                    </td>
                    <td>{list.no_surat}</td>
                    <td>{list.hal}</td>
                    <td>
                      <b>{list.pengirim.peg_nama}</b>
                      {list.satuan_kerja_nama}
                    </td>
                    <td>Unknow field Penerima</td>
                    <td>
                      {list.disposisi &&
                        <>
                          {list.disposisi.peg_nama}
                          <p>{list.disposisi.jabatan_nama}</p>
                          {list.disposisi.aksi.map((item, i) => {
                            return (
                              <p key={i}>
                                <span className='badge badge-primary'>
                                  {item}
                                </span>
                              </p>
                            )
                          })}
                        </>
                      }
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          meta={meta}
          increment={() => increment(meta.current_page, getList)}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;

import {
  BankSuratKeluar,
  BankSuratMasuk,
  BankSuratMasukSekretariat,
  BankSuratPage,
  LogsPage,
  MasterJabatanPage,
  MasterPegawaiKontrakPage,
  MasterPegawaiPage,
  MasterSatuanKerjaPage,
  MasterUnitKerjaPage,
  ProfilePage
} from "pages";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CariSuratPage from "pages/LoginPage/cariSurat";
import Content from "pages/StatistikPage/content";
import ContentToViewKeluar from "pages/BankSurat/Keluar/cards/contentToView";
import ContentToViewMasuk from "pages/BankSurat/Masuk/cards/contentToView";
import ContentToViewMasukSekretariat from "pages/BankSurat/Masuk-sekretariat/cards/contentToView";
import CreateFormSuratKeluar from "pages/SuratKeluar/Buat/index";
import CreateFormSuratMasuk from "pages/SuratMasuk/Buat";
import DaftarSuratMasuk from "pages/SuratMasuk/DaftarSuratMasuk";
import Dashboard from "pages/DashboardPage/Dashboard";
import DetailSurat from "pages/SuratKeluar/Detail/detailSurat";
import DetailSuratNonSekretariat from "pages/SuratKeluar/Detail/detailSuratNonSekretariat";
import DetailSuratSekretariat from "pages/SuratKeluar/Detail/detailSuratSekretariat";
import ForgetPassword from "pages/LoginPage/forgetPassword";
import KonsepPage from "pages/SuratKeluar/Konsep";
import Login from "pages/LoginPage/login";
import MasterKlasifikasiPage from "pages/ManajemenMaster/MasterKlasifikasi";
import MasterKopSuratPage from "pages/ManajemenMaster/MasterKopSurat";
import MasterPenggunaPage from "pages/ManajemenMaster/MasterPengguna";
import NotifikasiPage from "pages/notifikasi";
import { PersetujuanPage as Persetujuan } from "pages/SuratKeluar/Persetujuan";
import PrivateRoute from "./PrivateRoute";
import React from "react";
import ReactNotification from "react-notifications-component";
import Register from "pages/LoginPage/register";
import SuratBatalPage from "pages/SuratKeluar/Dibatalkan";
import SuratDetailList from "pages/SuratKeluar/Detail/details";
import TelusuriPage from "pages/SuratKeluar/Telusuri";
import Terkirim from "pages/SuratMasuk/Terkirim/terkirim";
import TerkirimPage from "pages/SuratKeluar/Terkirim";
import { Unsupport } from "components/body/unsupport";
import ViewRiwayatMasuk from "pages/BankSurat/Masuk/cards/viewListRiwayat";
import { logout_with_del_token_fcm } from "redux/loginRedux/LoginActios";
import { setStatus } from "redux/GeneralReducers/GeneralActions";
import MasterPenyimpanan from "pages/MenuArsip/MasterPenyimpanan";
import MasterJenisArsip from "pages/MenuArsip/MasterJenisArsip";
import MasterTabelArsip from "pages/MenuArsip/MasterTabelArsip";
import StatistikPage from "pages/MenuArsip/Statistik";
import { ViewPdfTesting } from "pages/BankSurat/Keluar/viewPdfTest";

const Navigate = (props) => {
  const dispatch = useDispatch();
  const { GeneralReducers, LoginReducers } = useSelector((state) => state);

  const myRoute = {
    // master arsip
    MasterPenyimpanan: MasterPenyimpanan,
    MasterJenisArsip: MasterJenisArsip,
    MasterTabelArsip: MasterTabelArsip,
    StatistikPage: StatistikPage,
    // master management
    MasterKopSuratPage: MasterKopSuratPage,
    MasterKlasifikasiPage: MasterKlasifikasiPage,
    MasterPenggunaPage: MasterPenggunaPage,
    Content: Content,
    // surat masuk
    DaftarSuratMasuk: DaftarSuratMasuk,
    CreateFormSuratMasuk: CreateFormSuratMasuk,
    Terkirim: Terkirim,
    // surat keluar
    CreateFormSuratKeluar: CreateFormSuratKeluar,
    Persetujuan: Persetujuan,
    TelusuriPage: TelusuriPage,
    KonsepPage: KonsepPage,
    TerkirimPage: TerkirimPage,
    SuratBatalPage: SuratBatalPage,
    // detail
    DetailSuratSekretariat: DetailSuratSekretariat,
    DetailSuratNonSekretariat: DetailSuratNonSekretariat,
    DetailSurat: DetailSurat,
    NotifikasiPage: NotifikasiPage,
    BankSuratPage: BankSuratPage,
    BankSuratMasuk: BankSuratMasuk,
    BankSuratKeluar: BankSuratKeluar,
    BankSuratMasukSekretariat: BankSuratMasukSekretariat,
    LogsPage: LogsPage,
  };

  return (
    <BrowserRouter>
      {GeneralReducers.unauthorized && (
        <div className="show-dark">
          <div className="text-warn">
            {GeneralReducers.msg !== "Network Error" ? (
              <>
                Unauthorized
                <a
                  href="# "
                  onClick={() => dispatch(logout_with_del_token_fcm())}
                  className="btn btn-primary"
                >
                  Login
                </a>
              </>
            ) : (
              <>
                {GeneralReducers.msg}
                <a
                  href="# "
                  onClick={() =>
                    dispatch(setStatus({ status: null, msg: null }))
                  }
                  className="btn btn-primary"
                >
                  Close
                </a>
              </>
            )}
          </div>
        </div>
      )}

      <ReactNotification />

      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/cari" component={CariSuratPage} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <Route
          exact
          path="/test-view-pdf"
          component={ViewPdfTesting}
        />
        <Route exact path="/unsupport-browser" component={Unsupport} />
        <PrivateRoute exact path="/dashboard" Component={Dashboard} />
        <PrivateRoute
          exact
          path="/dashboard/personal-info"
          Component={ProfilePage}
        />


        {/* untuk sementara link */}
        {/* <PrivateRoute
          exact
          path="/bank-surat/masuk/list"
          Component={BankSuratMasuk}
        />
        <PrivateRoute
          exact
          path="/bank-surat/keluar/list"
          Component={BankSuratKeluar}
        />
        <PrivateRoute
          exact
          path="/bank-surat/masuk-sekretariat/list"
          Component={BankSuratMasukSekretariat}
        /> */}
        <PrivateRoute
          exact
          path="/bank-surat/list-surat/listBankSuratKeluarPrinted"
          Component={ContentToViewKeluar}
        />
        <PrivateRoute
          exact
          path="/bank-surat/list-surat/listBankSuratMasukPrinted"
          Component={ContentToViewMasuk}
        />
        <PrivateRoute
          exact
          path="/bank-surat/list-surat/listBankSuratMasukSekretariatPrinted"
          Component={ContentToViewMasukSekretariat}
        />
        <PrivateRoute
          exact
          path="/bank-surat/masuk-sekretariat/list/detail/:id"
          Component={DetailSuratSekretariat}
        />
        <PrivateRoute
          exact
          path="/bank-surat/:type/list/detail/:id"
          Component={SuratDetailList}
        />
        <PrivateRoute
          exact
          path="/bank-surat/print-view-riwayat/:id"
          Component={ViewRiwayatMasuk}
        />
        <PrivateRoute
          exact
          path="/manajemen-master/satuan-kerja"
          Component={MasterSatuanKerjaPage}
        />
        <PrivateRoute
          exact
          path="/manajemen-master/unit-kerja"
          Component={MasterUnitKerjaPage}
        />
        <PrivateRoute
          exact
          path="/manajemen-master/jabatan"
          Component={MasterJabatanPage}
        />
        <PrivateRoute
          exact
          path="/manajemen-master/pegawai"
          Component={MasterPegawaiPage}
        />
        <PrivateRoute
          exact
          path="/notifikasi"
          Component={NotifikasiPage}
        />
        <PrivateRoute
          exact
          path="/manajemen-master/tenaga-kontrak"
          Component={MasterPegawaiKontrakPage}
        />
        {/* will delete soon */}
        <PrivateRoute
          exact
          path="/master-arsip/master-penyimpanan"
          Component={MasterPenyimpanan}
        />
        <PrivateRoute
          exact
          path="/master-arsip/master-jenis-arsip"
          Component={MasterJenisArsip}
        />
        <PrivateRoute
          exact
          path="/master-arsip/tabel-arsip"
          Component={MasterTabelArsip}
        />
        <PrivateRoute
          exact
          path="/master-arsip/statistik"
          Component={StatistikPage}
        />

        {/* detail bank surat linknya --> /bank-surat/list/detail/:id */}
        {LoginReducers.routers.map((item, i) => {
          return (
            <PrivateRoute
              key={i}
              exact
              path={item.path}
              Component={myRoute[item.component]}
            />
          );
        })}
        <Route exact path="*" component={() => <h1>Not Found !</h1>} />
      </Switch>
    </BrowserRouter>
  );
};

export default Navigate;

import ModalComponent from "./modals";
import Paginate from "components/footers/paginate";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import Loading from "components/body/Loading";
import SelectSatuanKerja from "components/body/selects/selectSatuanKerja";

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers } = useSelector((state) => state);
  const [isOpen, setOpen] = useState(null);
  const [jabatan, setJabatan] = useState({
    action: "add",
    status: true,
    isJftOrJfu: false,
    isTenagaKontrak: false,
  })
  const { loading } = GeneralReducers;
  const { items, meta } = ListSuratReducers.listMasterJabatan;

  const getList = (current_page = 1) => {
    dispatch(getListMethod(`pegawai-egs/api/satuan-kerja`, "listMasterSatuanKerja", current_page));
    dispatch(getListMethod(`pegawai-egs/api/jabatan`, "listMasterJabatan", current_page));
    dispatch(getListMethod(`pegawai-egs/api/unit-kerja/?data`, "listMasterUnitKerja", current_page));
  };

  const changeFilterList = (e) => {
    dispatch(getListMethod(`pegawai-egs/api/jabatan`, "listMasterJabatan", 1, e.value));
  }

  const resetValue = () => {
    setOpen(false);
    setJabatan({ action: "add", status: true })
  }

  const addJabatan = () => {
    setOpen(true);
    setJabatan({
      ...jabatan,
    })
  }
  const addChild = (item) => {
    console.log("add child", item);
    let isJftOrJfu = item.jenis_jabatan_nama === "JFU" || item.jenis_jabatan_nama === "JFT"
    setOpen(true);
    setJabatan({
      ...jabatan,
      ...item,
      parentName: item.name,
      parent_id: item.id,
      action: "addChild",
      isJftOrJfu: isJftOrJfu,
    })
  }

  const editChild = (item, parent) => {
    setOpen(true);
    setJabatan({
      ...jabatan,
      ...item,
      parent_id: parent.id,
      parentName: parent.name,
      isJftOrJfu: item.jenis_jabatan_nama === "JFU" || item.jenis_jabatan_nama === "JFT",
      action: "editChild"
    })
  }

  const editParent = (item) => {
    setOpen(true);
    setJabatan({
      ...item,
      ...jabatan,
      action: "edit"
    })
  }
  // console.log("item name", items);
  // console.log("jataban state", jabatan);
  useEffect(getList, []);

  return (
    <Fragment>
      <ModalComponent
        isOpen={isOpen}
        resetValue={resetValue}
        jabatan={jabatan}
        getList={getList}
        setJabatan={setJabatan}
      />
      <div className="content-title row">
        <div className="col-md-12 col-sm-12 mb-2">
          <a
            onClick={addJabatan}
            href="# "
            className="btn btn-sm btn-primary"
          >
            <i className="fa fa-plus"></i> Tambah Jabatan
          </a>
        </div>
        <div className="col-md-12 col-sm-12">
          <SelectSatuanKerja
            placeholder="Pilih SKPD"
            className="m-b-5"
            list="listMasterSatuanKerja"
            sourceReducer="ListSuratReducers"
            // defaultValue={{ value: pegawai.unit_kerja_id, label: pegawai?.unitKerja?.name }}
            onChange={changeFilterList}
          />
        </div>
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <th>Satuan Kerja</th>
              <th>Jenis Jabatan</th>
              <th>Unit Kerja</th>
              <th>Jabatan</th>
              <th>Kode</th>
              <th>Eselon</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {items && items.map(((list, i) => {
              return (
                <Fragment key={i}>
                  <tr>
                    <td>
                      {list.SatuanKerja.name}
                    </td>
                    <td>
                      {list.jenis_jabatan_nama}
                    </td>
                    <td>
                      {list.UnitKerja.name}
                    </td>
                    <td>
                      <i className="fa-chevron-down fa mr-1"></i>
                      {list.name}
                    </td>
                    <td>
                      {list.code}
                    </td>
                    <td>{list.eselon?.eselon_id ? list.eselon.eselon_nm : ""}</td>
                    <td>
                      <button size="sm" variant="default" className="btn btn-sm btn-primary mr-1" onClick={() => addChild(list)}>
                        <i className="fa fa-plus-circle"></i>
                      </button>
                      <button size="sm" variant="default" className="btn btn-sm btn-primary" onClick={() => editParent(list)}>
                        <i className="fa fa-pencil"></i>
                      </button>
                    </td>
                  </tr>
                  <ContentTable key={i} data={list} pl={8} addChild={addChild} editChild={editChild} />
                </Fragment>
              )
            }))}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          increment={() => increment(meta.current_page, getList)}
          meta={meta}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};


const ContentTable = ({ data, pl, addChild, editChild }) => {
  // console.log("dataaaa", data);

  if (!data.childs) {
    return <></>
  }

  return data.childs.map((item, i) => {
    return (
      <Fragment key={i}>
        <tr>
          <td></td>
          <td>JFT</td>
          <td></td>
          <td style={{
            "display": "flex",
            "flexDirection": "row",
            "paddingLeft": `${pl}%`
          }}>
            {item.childs.length > 0 ?
              <i className="fa-chevron-down fa mr-1"></i> :
              <i className="fa-chevron-right fa mt-1 mr-1"></i>
            }
            <span> {item.name}</span>
          </td>
          <td>{item.code}</td>
          <td>{item.eselon?.eselon_id ? item.eselon.eselon_nm : ""}</td>
          <td width="100px">
            <button className="btn btn-sm btn-primary mr-1" onClick={() => addChild(item)}>
              <i className="fa fa-plus-circle"></i>
            </button>
            <button className="btn btn-sm btn-primary" onClick={() => editChild(item, { name: data.name, id: data.id })}>
              <i className="fa fa-pencil"></i>
            </button>
          </td>
        </tr>
        <ContentTable key={i} data={item} pl={pl + 3} addChild={addChild} editChild={editChild} />
      </Fragment>
    )
  })
}

export default Content;


import React, { Fragment, useState } from "react";
import Buttons from "components/body/buttons/Button";
import { Modal } from "react-bootstrap";
import SelectSatuanKerja from "components/body/selects/selectSatuanKerja";
import { delRequest, getRequest, postRequest, putRequest } from 'config/axios'
import { useDispatch } from "react-redux";
import Select from 'react-select';
import { getListSelect } from "redux/SelectReducers/SelectAction";
import { set_option_default } from "redux/SelectReducers/SelectAction";
import { useEffect } from "react";
import { useCallback } from "react";

const ModalComponent = ({ isOpen, setOpen, pegawai, setPegawai, getList }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [jenisJabatan, setJenisJabatan] = useState([])
  const [jabatan, setJabatan] = useState([])

  const postPegawai = () => {
    setLoading(true)
    postRequest('egs/pegawai-egs/api/pegawai', { ...pegawai })
      .then(res => {
        resetModal();
        getList();
      }).catch(error => {
        setLoading(false)
        console.log("error post pegawai", error)
      })
  }

  const updatePegawai = (id) => {
    setLoading(true)
    putRequest(`egs/pegawai-egs/api/pegawai/${id}`, { ...pegawai })
      .then(res => {
        resetModal();
        getList();
      }).catch(error => {
        setLoading(false)
        console.log("error post pegawai", error)
      })
  }

  const deleteJabatan = (id) => {
    setLoading(true)
    delRequest(`egs/pegawai-egs/api/pegawai/${id}`)
      .then(res => {
        resetModal();
        getList();
      }).catch(e => {
        setLoading(false);
        console.log("error get list of modals add jabatan", e)
      })
  }

  const changeSatuanKerja = useCallback((id) => {
    dispatch(getListSelect(`pegawai-egs/api/unit-kerja`, "unitKerja", id));
  }, [dispatch])

  const changeUnitKerja = useCallback((id) => {
    dispatch(getListSelect(`pegawai-egs/api/jabatan`, "jabatan", id));
  }, [dispatch])

  const getListOfJenisJabatan = () => {
    getRequest(`egs/pegawai-egs/api/jabatan/get-data/jenis-jabatan`)
      .then(res => {
        setJenisJabatan(() => res.data.items.map(item => ({ value: item.id, label: item.name })))
      }).catch(e => console.log("error get list of modals add jabatan", e))
  }

  const getListOfJabatan = (id) => {
    getRequest(`egs/pegawai-egs/api/jabatan?jenis_jabatan=${id}`)
      .then(res => {
        setJabatan(() => res.data.items.map(item => ({ value: item.jabatan_id, label: item.jabatan_nama })))
      }).catch(e => console.log("error get list of modals add jabatan", e))
  }

  const resetModal = () => {
    setOpen(false);
    setLoading(false);
    dispatch(set_option_default());
    setPegawai({ status: true, action: "add" })
  }

  useEffect(() => {
    if (pegawai.satuan_kerja_id) {
      changeSatuanKerja(pegawai.satuan_kerja_id)
    }
  }, [changeSatuanKerja, pegawai.satuan_kerja_id]);

  useEffect(() => {
    if (pegawai.jabatan_id) {
      changeUnitKerja(pegawai.jabatan_id)
    }
  }, [changeUnitKerja, pegawai.jabatan_id]);

  useEffect(() => {
    getListOfJenisJabatan()
  }, []);

  useEffect(() => {
    if (pegawai.jenis_jabatan) {
      getListOfJabatan(pegawai.jenis_jabatan)
    }
  }, [pegawai.jenis_jabatan]);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={isOpen}
        onHide={resetModal}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            {pegawai.action === "add" ? "Tambah " : "Ubah "} Pegawai
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="form-group">
                  <label htmlFor="nama">Nama*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={pegawai?.name}
                    onChange={(e) => setPegawai({ ...pegawai, name: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="nip">NIP*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={pegawai?.nip}
                    onChange={(e) => setPegawai({ ...pegawai, nip: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="satuan_kerja">Satuan Kerja*</label>
                  <SelectSatuanKerja
                    placeholder="Pilih Satuan Kerja"
                    className="m-b-5"
                    list="listMasterSatuanKerja"
                    sourceReducer="ListSuratReducers"
                    defaultValue={{ value: pegawai?.SatuanKerja?.id, label: pegawai?.SatuanKerja?.name }}
                    onChange={(e) => {
                      setPegawai({ ...pegawai, satuan_kerja_id: e.value })
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="unit_kerja">Unit kerja*</label>
                  <SelectSatuanKerja
                    placeholder="Pilih Unit Kerja"
                    className="m-b-5"
                    list="unitKerja"
                    defaultValue={{ value: pegawai?.UnitKerja?.id, label: pegawai?.UnitKerja?.name }}
                    sourceReducer="ListOptionReducers"
                    onChange={(e) => {
                      setPegawai({ ...pegawai, unit_kerja_id: e.value })
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="jenis_jabatan">Jenis Jabatan*</label>
                  <Select
                    placeholder="Pilih Jenis"
                    className="m-b-5"
                    defaultValue={{
                      value: pegawai?.jabatan?.jenis_jabatan,
                      label: pegawai?.jabatan?.jenis_jabatan_nama
                    }}
                    options={jenisJabatan}
                    onChange={(e) => setPegawai({ ...pegawai, jenis_jabatan: e.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="jabatan">Jabatan*</label>
                  <Select
                    placeholder="Pilih Jenis"
                    className="m-b-5"
                    defaultValue={{
                      value: pegawai?.jabatan?.id,
                      label: pegawai?.jabatan?.name
                    }}
                    options={jabatan}
                    onChange={(e) => setPegawai({ ...pegawai, jabatan_id: e.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="alamat">Alamat*</label>
                  <input
                    type="text"
                    className="form-control"
                    value={pegawai?.alamat}
                    onChange={(e) => setPegawai({ ...pegawai, alamat: e.target.value })}
                  />
                </div>
                <div className="form-group row">
                  <div className="col-md-6">
                    <label htmlFor="telp">No. Telp / WA*</label>
                    <input
                      type="text"
                      className="form-control"
                      value={pegawai?.telp}
                      onChange={(e) => setPegawai({ ...pegawai, telp: e.target.value })}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email">Email*</label>
                    <input
                      type="email"
                      className="form-control"
                      value={pegawai?.email}
                      onChange={(e) => setPegawai({ ...pegawai, email: e.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-6 mb-3">
              {pegawai.id &&
                <Buttons
                  className="btn btn-danger mr-2"
                  children="Hapus"
                  loading={loading}
                  onClick={() => deleteJabatan(pegawai.id)}
                />
              }
            </div>
            <div className="col-md-6 text-right mb-3">
              <Buttons
                onClick={resetModal}
                className="btn btn-default mr-2"
                children="Batal"
              />
              {pegawai.action === 'edit' ?
                <Buttons
                  className="btn btn-primary"
                  children="Ubah"
                  loading={loading}
                  onClick={() => updatePegawai(pegawai.id)}
                />
                :
                <Buttons
                  className="btn btn-primary"
                  children="Simpan"
                  loading={loading}
                  onClick={postPegawai}
                />
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalComponent;

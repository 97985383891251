import React, { Fragment, useEffect, useState } from "react";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";

import { Button } from "react-bootstrap";
import Loading from "components/body/Loading";
import ModalComponent from "./modals";
import Paginate from "components/footers/paginate";
import SelectSatuanKerja from "components/body/selects/selectSatuanKerja";
import getListFromRedux from 'utils/list-surat-helper';
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import { useDispatch } from "react-redux";
import { getListSelect } from "redux/SelectReducers/SelectAction";

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers, LoginReducers } = getListFromRedux();
  const [isOpen, setOpen] = useState(null);
  const [idPegawaiSelected, setIdPegawaiSelected] = useState()
  const [pegawai, setPegawai] = useState({ status: true, action: "add" })

  const { loading } = GeneralReducers;
  const { items, meta } = ListSuratReducers.listMasterPegawai;
  const satuan_kerja_id = LoginReducers.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || 0;
  const getListMaster = (current_page = 1) => {
    dispatch(getListSelect(`pegawai-egs/api/satuan-kerja`, "satuanKerja", satuan_kerja_id));
    dispatch(getListMethod(`pegawai-egs/api/satuan-kerja`, "listMasterSatuanKerja", current_page, satuan_kerja_id));
    dispatch(getListMethod(`pegawai-egs/api/unit-kerja/?data`, "listMasterUnitKerja", current_page));
    dispatch(getListMethod(`pegawai-egs/api/jabatan`, "listMasterJabatan", current_page));
    dispatch(getListMethod(`pegawai-egs/api/pegawai`, "listMasterPegawai", current_page));
  };

  const getList = () => {
    dispatch(getListMethod(`pegawai-egs/api/pegawai`, "listMasterPegawai", 1, idPegawaiSelected));
  }

  const editItem = (item) => {
    setOpen(true);
    setPegawai({ ...item, action: "edit" })
  }

  useEffect(getListMaster, []);
  useEffect(getList, [idPegawaiSelected]);

  return (
    <Fragment>
      <ModalComponent
        isOpen={isOpen}
        setOpen={setOpen}
        pegawai={pegawai}
        getList={getList}
        setPegawai={setPegawai}
      />
      <div className="content-title row">
        <div className="col-md-12 col-sm-12 mb-2">
          <a
            onClick={() => setOpen(true)}
            href="# "
            className="btn btn-sm btn-primary"
          >
            <i className="fa fa-plus"></i> Tambah Pegawai
          </a>
        </div>
        <div className="col-md-12 col-sm-12">
          <SelectSatuanKerja
            placeholder="Pilih Satuan Kerja"
            className="m-b-5"
            list="listMasterSatuanKerja"
            sourceReducer="ListSuratReducers"
            onChange={(e) => setIdPegawaiSelected(e.value)}
          />
        </div>
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>Nama</td>
              <td>NIP</td>
              <td>Satuan Kerja</td>
              <td>Unit Kerja</td>
              <td>Jenis Jabatan</td>
              <td>Jabatan</td>
              <td>Edit</td>
            </tr>
          </thead>
          <tbody>
            {items && items.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item.name}</td>
                  <td>{item.nip}</td>
                  <td>
                    {item.SatuanKerja.name}
                  </td>
                  <td>{item.UnitKerja?.name}</td>
                  <td>{item.jabatan.jenis_jabatan_nama}</td>
                  <td> {item.jabatan.name} </td>
                  <td>
                    <Button size="sm" variant="default" onClick={() => editItem(item)}>
                      <i className="fa fa-pencil"></i>
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          increment={() => increment(meta.current_page, getList)}
          meta={meta}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
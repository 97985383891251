import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
// import { delRequest } from 'config/axios';
import Loading from 'components/body/Loading';
import Paginate from 'components/footers/paginate';
import { useDispatch, useSelector } from 'react-redux';
import { exportExcel, getListMethod, getListOfPrintedView, set_list } from 'redux/SuratReducers/ListSuratMasuk/ListSuratAction';
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from 'utils/pagination';
import { getRequest } from 'config/axios';

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers, LoginReducers } = useSelector(
    (state) => state
  );
  const [jenisSurat, setJenisSurat] = useState([]);
  const [statusSurat, setStatusSurat] = useState([]);
  const { loading, status, msg } = GeneralReducers;
  const { jenis_surat, status_surat, start_date, end_date } = ListSuratReducers;
  const { items, meta } = ListSuratReducers.listBankSuratKeluar;
  let satuan_kerja_id =
    LoginReducers.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || 0;
  const getList = useCallback((page = 1, per_page = 10) => {
    dispatch(
      getListMethod(
        `master/bank-mail-keluar`,
        'listBankSuratKeluar',
        page,
        satuan_kerja_id,
        per_page
      )
    );
    dispatch(
      getListOfPrintedView(
        `bank-mail-keluar`,
        'listBankSuratKeluarPrinted',
      )
    );
  }, [dispatch, satuan_kerja_id]);

  const getJenisSurat = useCallback(() => {
    getRequest('egs/mail-type')
      .then((e) => {
        setJenisSurat(e.data.items)
      }).catch(e => console.log("error get jenis surat", e))
  }, []);

  const getStatusSurat = useCallback(() => {
    getRequest('egs/mail-status')
      .then((e) => {
        setStatusSurat(e.data.items)
      }).catch(e => console.log("error get jenis surat", e))
  }, []);

  const downloadExcel = () => {
    dispatch(
      exportExcel(
        `bank-mail-keluar`,
        'listBankSuratKeluarPrinted',
      )
    );
  }

  useEffect(() => {
    getList()
  }, [start_date, end_date, getList])

  useEffect(() => {
    getList()
    getJenisSurat()
    getStatusSurat()
  }, [getList, getJenisSurat, getStatusSurat]);
  useEffect(getList, [jenis_surat]);
  useEffect(getList, [status_surat]);
  console.log("ListSuratReducers", ListSuratReducers)
  return (
    <Fragment>
      <div className='content-body'>
        {status === 'success-bank-surat' && (
          <div className='alert alert-success'>{msg}</div>
        )}
        {status === 'fail-bank-surat' && (
          <div className='alert alert-danger'>{msg}</div>
        )}
        <Loading isLoading={loading} />
        <div className="form-group row">
          <div className="col-md-4">
            <label htmlFor="jenis_surat"></label>
            <select
              className="form-control"
              defaultValue={jenis_surat}
              onChange={(e) => {
                dispatch(set_list({ name: "jenis_surat", value: e.target.value }))
              }}
            >
              <option value="">Semua</option>
              {jenisSurat.map((opt, i) => {
                return (
                  <option key={i} value={opt.type}>{opt.nama}</option>
                )
              })}
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="status_surat"></label>
            <select
              className="form-control"
              defaultChecked={status_surat}
              onChange={(e) => {
                dispatch(set_list({ name: "status_surat", value: e.target.value }))
              }}
            >
              <option value="">Pilih Status</option>
              {statusSurat.map((opt, i) => {
                return (
                  <option key={i} value={opt.name}>{opt.name}</option>
                )
              })}
            </select>
          </div>
          <div className="col-md-2" style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "end" }}>
            <div className="dropdown">
              <button style={{ backgroundColor: "transparent", borderColor: "#eaeaea" }} className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                Export
              </button>
              <div className="dropdown-menu">
                <Link rel="noopener noreferrer" className="dropdown-item" to="# " onClick={downloadExcel}><i className="fa fa-file-excel-o" ></i> Excel</Link>
                <Link className="dropdown-item" to={{ pathname: "/bank-surat/list-surat/listBankSuratKeluarPrinted" }}><i className="fa fa-file-pdf-o"></i> PDF</Link>
              </div>
            </div>
          </div>
          <div className="col-md-3" style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "end" }}>
            <label htmlFor="tampilkan_surat">Tampilkan</label>
            <select
              className="form-control"
              defaultChecked={status_surat}
              onChange={(e) => {
                getList(1, e.target.value)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
            <label htmlFor="status_surat">Entri</label>
          </div>
        </div>

        <table className='table table-striped table-list'>
          <thead>
            <tr>
              <td width='130px'>Tanggal Di Buat</td>
              <td>Nomor Surat</td>
              <td>Hal</td>
              <td>Pengirim</td>
              <td>Penerima</td>
              <td>Status</td>
              <td>Jenis Surat</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((list, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Link to={`/bank-surat/keluar/list/detail/${list.id}`}>
                        {moment(list.tanggal).format('LL')}
                      </Link>
                    </td>
                    <td>{list.no_surat}</td>
                    <td>{list.hal}</td>
                    <td>
                      <b>{list.pengirim.peg_nama}</b>
                      <p>{list.pengirim.peg_jabatan}</p>
                      {list.dari}
                    </td>
                    <td>
                      {/* <b>{list.pengirim.peg_nama}</b>
                      <p>{list.pengirim.peg_jabatan}</p>
                      {list.dari} */}
                      {list.dari}
                    </td>
                    <td>
                      <span className='badge badge-primary'>
                        {list.status.text}
                      </span>
                    </td>
                    <td>{list.status_mail}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          meta={meta}
          increment={() => increment(meta.current_page, getList)}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
